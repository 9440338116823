import { ApiError } from './ApiError'
import { RequestMetadata } from './RequestMetadata'
import { ActionItemSearchResponse } from '../models/Search/ActionItem'
import ReferenceData from "../models/ReferenceData/ReferenceDataResponse";
import CustomerResponse from '../models/Customer/CustomerResponse';
import PropertyResponse from '../models/Property/PropertyResponse'
import { trackEvent } from "../appInsights/TelemetryService";
import AssetDetails from '../models/Customer/AssetDetails';
import CustomerResponseDetails from '../models/Customer/CustomerResponseDetails';
import Tenancies from '../models/Customer/Tenancies';
import PremisesEligibility from '../models/Customer/PremisesEligibility';
import { CurrentRepair, RepairHistory, RepairNote } from '../models/Repair/Repair';
import { WorksOrderRequest } from '../models/WorksOrders/WorksOrderRequest';
import { SorDeterminationResponse, WorksOrder, WorksOrdersResponse } from '../models/WorksOrders';
import { EligibilityResponse, PremisesDetails } from '../models/Property';
import { RepairReason } from "../models/Repair/RepairReason";
import { PremisesType } from '../models/Customer/Premises';
import { ContractorV2 } from '../models/Repair/ContractorV2';
import { ContractorPriority } from '../models/Repair/Contractor';
import moment from 'moment';
import { CustomerSecurityProfile } from '../models/Customer/CustomerSecurityProfile';
import CustomerProfile from '../models/Customer/CustomerProfile';
import TokenResponse from '../models/TokenResponse';
import { CustomersForAssetResponse } from '../models/Customer/CustomerAssetResponse';
import { CancelEligibilityOutcome } from '../models/Property/Eligibility';
import {CreateArrearsDataType, UntidyTenancyTypes} from '../models/UCC/UniversalCreditCard';
import CaseSearchData from '../models/Case/CaseResponse';
import { caseResponseData } from "./../models/Case/Case";
import { CaseHistory } from '../models/Case/CaseHistory';
import { isUndefinedNullOrEmpty } from '../utils/translation/helper';
import {EnhancedRepairPayload, EnhancedRepairResponse} from "../models/EnhancedRepair/index";



const apimBaseUrl = process.env.REACT_APP_APIM_BASE_URL
const apimKey = process.env.REACT_APP_OCP_APIM_KEY
const origin = process.env.REACT_APP_WEB_APP_HOSTNAME
const tokenApiBaseUrl = process.env.REACT_APP_LOGIN_TOKEN_API_BASE_URL;
const redirectApiBaseUrl=process.env.REACT_APP_REDIRECT_URL;
const searchActionItemsApiUrl = `${apimBaseUrl}/action-items-api/api/v1`
const accountsApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1`;
const assetapiApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v2`;
const transactionApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1`;
const premisesEligibilityApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1`;
const worksordersApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v2`;
const referenceApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1/`;
const customerApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v2/`;
const assetApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v2/assets`;
const appconfigApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1/`;
const tenanciesApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1`;
const referenceDataV2ApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v2`;
const contractorV2ApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v2`;
const securityProfileApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1`
const clearSessionUrl = `${apimBaseUrl}/colleagueselfserve`;
const noteApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1`;
const cancelRepairApiUrl = `${apimBaseUrl}/colleagueselfserve`;
const caseApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1/cases`;
const droolAPIUrl = `${apimBaseUrl}/colleagueselfserve`;
const mediaApiUrl = `${apimBaseUrl}/colleagueselfserve/api/`;
const enhancedRepairEligibilityApiURL = `${apimBaseUrl}/colleagueselfserve`;
const eligilibilityDeterminationApiURL = `${apimBaseUrl}/colleagueselfserve`;
const getNotesBaseApiUrl = `${apimBaseUrl}/colleagueselfserve/api/v1`


const tokenApiUrl = `${tokenApiBaseUrl}?`;
const redirect_uri = `${redirectApiBaseUrl}`;



export type SearchParameters = {
    resultsPerPage: number
    skip: number
    orderBy: string
}

export const caseDroolAPI = (data: any): ApiResponse<any> =>
  post(droolAPIUrl)(`/case-team-determination`, data);

export const createCaseData = (data: any): ApiResponse<caseResponseData> =>
  post(referenceApiUrl)(`cases`, data);

export const getRepairHistories = async (
  workOrderID: string
): ApiResponse<RepairHistory> => {
  const repairHistoriesResponse = await get(customerApiUrl)(
    `works-orders/${workOrderID}/historyItems`
  );
  return repairHistoriesResponse;
};

export const getRepairNotes = async (
    workOrderJobSequenceNumber: string
): ApiResponse<RepairNote[]> => {
    const repairNotesResponse = await get(noteApiUrl)(
        `/workorders/${workOrderJobSequenceNumber}/notes`
    );
    return repairNotesResponse;
};

export const getAccountByCustomers = (id: string): ApiResponse<any> =>
  get(accountsApiUrl)(`/customers/${id}/accounts`);

export const SearchActionItemsData = (opts: SearchParameters): ApiResponse<ActionItemSearchResponse> =>
    get(searchActionItemsApiUrl)(`/actionItems?limit=${opts.resultsPerPage}&skip=${opts.skip}&orderby=${opts.orderBy}`)

export const SearchActionItemsDataBySearchText = (opts: SearchParameters, searchTerm:string): ApiResponse<ActionItemSearchResponse> =>
    get(searchActionItemsApiUrl)(`/actionItems?searchText=${searchTerm}&limit=${opts.resultsPerPage}&skip=${opts.skip}&orderby=${opts.orderBy}`)

export const UpdateActionItemsData = (id: string, contractor: string, workItemStatus: string): ApiResponse<any> => {
    if (contractor === null || contractor === '')
        return patch(searchActionItemsApiUrl)(`/actionItems/${id}`, { "status": workItemStatus })
    else
        return patch(searchActionItemsApiUrl)(`/actionItems/${id}`, { "status": workItemStatus, "contractor": [contractor] })
}
export const getCustomer = (id: string): ApiResponse<CustomerResponseDetails> =>
  get(customerApiUrl)(`customers/${id}`);

  export const getTenancies = (customerId: string): ApiResponse<Tenancies[]> =>
  get(tenanciesApiUrl)(`/customers/${customerId}/tenancies`);

  export const postPremisesEligibility = (
    premiseId: string,
    tenancyGroup: string
  ): ApiResponse<PremisesEligibility[]> =>
    post(premisesEligibilityApiUrl)(
      `/premises/${premiseId}/eligibilityoutcomes`,
      { tenancyGroup }
    );
    export const getReferenceData = (
      namespacenames: string
    ): ApiResponse<ReferenceData[]> =>
      get(referenceApiUrl)(
        `reference-data/assets/reference-data?namespacenames=${namespacenames}`
      );
      export const getServeyReferenceData = (
        surveyreasons: string
      ): ApiResponse<ReferenceData[]> =>
        get(referenceApiUrl)(
          `surveys/reference-data?namespacenames=${surveyreasons}`
        );
      export const getAssetsByid = (assetId: string): ApiResponse<AssetDetails> =>
      get(assetapiApiUrl)(`/assets/${assetId}`);

export const getAssociatedAssets = (
  assetId: string
): ApiResponse<AssetDetails> =>
  get(assetapiApiUrl)(`/assets/${assetId}/assets?expand=address`);

  export const getAssociatedAssetsKey = (
    assetId: string
  ): ApiResponse<AssetDetails[]> =>
    get(assetapiApiUrl)(`/assets/${assetId}/assets?expand=address`);
  
  export const getCustomerAssetApi = (
    assetId: string
  ): ApiResponse<CustomersForAssetResponse> =>
    get(assetapiApiUrl)(`/customers?propertyId=${assetId}&isActiveTenant=true`);

  export const getTenanciesGroup = (
    customerId: string,
    premiseId: string
  ): ApiResponse<Tenancies[]> =>
    get(tenanciesApiUrl)(
      `/customers/${customerId}/premises/${premiseId}/tenancies`
    );


export const GetUserName = (
  code: string
): ApiResponse<TokenResponse> =>
  post(tokenApiUrl)(`code=${code}&redirect_uri=${redirect_uri}`, {});

  export const clearSession = (): ApiResponse<any> =>
  deleteRequest(clearSessionUrl)(`/logout`);
  
type ApiResponse<T> = Promise<T>;
const ApiResponse = Promise;
let accountId: string = '';

export const setAccountId = (aid: string) => {
    accountId = aid
}
export const getRepair = async (id: string): ApiResponse<CurrentRepair> => {
  const worksOrderResponse: WorksOrder = await get(worksordersApiUrl)(
    `/works-orders/${id}`
  );

  return currentRepairFromWorksOrder(worksOrderResponse);
};

export const currentRepairFromWorksOrder = async (
  worksOrder: WorksOrder
): Promise<CurrentRepair> => {
  let premisesInfo: PremisesDetails;

  if (worksOrder.identifiers?.asset?.assetIdentifier) {
    premisesInfo = await get(assetapiApiUrl)(
      `/assets/${worksOrder.identifiers.asset.assetIdentifier}`
    );
    const currentRepair = new CurrentRepair(worksOrder, premisesInfo);
    return currentRepair;
  }
  return new CurrentRepair(worksOrder);
};

export const getWorkOrder = async (id: string): ApiResponse<WorksOrder> => {
    const worksOrderResponse: WorksOrder = await get(worksordersApiUrl)(
        `/works-orders/${id}`
    );
    return worksOrderResponse;
};
export const getWorkOrderEntity = async (id: string): ApiResponse<CurrentRepair> => {
  const worksOrderResponse: WorksOrder = await get(worksordersApiUrl)(
    `/works-orders/${id}`
  );

  const currentRepair = new CurrentRepair(worksOrderResponse);
  return currentRepair;
};

export const deleteWorkOrder = async (
  workOrderID: string,
  eTag: any
): ApiResponse<RepairHistory> => {
  let metadata = { eTag: eTag };
  const deleteWorkOrderResponse = await deleteRequest(worksordersApiUrl)(
    `/works-orders/${workOrderID}`,
    {},
    metadata
  );
  return deleteWorkOrderResponse;
};
export const getpermissions = (objectId: string): ApiResponse<any> => {
  return get(securityProfileApiUrl)(`/securityprofile/${objectId}/permissions`)
};
const searchRepairReasonsV2 = (
  searchText: string,
  assetType: string,
  isCatalyst: boolean
): ApiResponse<RepairReason[]> =>
  get(referenceDataV2ApiUrl)(
    `/repair-reasons?searchText=${searchText}&assetType=${assetType}&isUsedForCreation=true&isCatalyst=${isCatalyst}`
  );
  export const getCustomerSecurityProfile = (customerID: string): ApiResponse<CustomerSecurityProfile> => {
    return get(securityProfileApiUrl)(`/customers/${customerID}/securityprofile`)
};

export const searchRepairReasons = (searchText: string, assetType: string, isCatalyst: boolean) =>
  searchRepairReasonsV2(searchText, assetType, isCatalyst);
  export const getSorDetermination = ({
    propertyAttribute,
    dividedBlockAttribute,
    blockAttribute,
    estateAttribute,
    context,
  }: {
    propertyAttribute: string;
    dividedBlockAttribute: string;
    blockAttribute: string;
    estateAttribute: string;
    context: PremisesType;
  }): ApiResponse<SorDeterminationResponse> =>
    // Use default GUID for works-orders ID as we do not have an actual ID at this point
    post(worksordersApiUrl)(
      `/works-orders/00000000-0000-0000-0000-000000000000/sor-determination`,
      {
        context,
        propertyAttribute,
        dividedBlockAttribute,
        blockAttribute,
        estateAttribute,
      }
    );
    export const getRepairEligibility = (
      premisesType: string,
      maintenanceResponsibility: string,
      repairResponsibility: string,
      additionalRepairInfo: boolean,
      isRepairEligible: boolean | null,
      isCustomerView: boolean,
      sorCode: string | null,
      uprn: string,
      isInDefects: boolean,
      heatingRepairContext: string | null
    ): ApiResponse<EligibilityResponse> =>
      post(worksordersApiUrl)(`/works-orders/eligibilityoutcomes`, {
        premisesType,
        maintenanceResponsibility,
        repairResponsibility,
        additionalRepairInfo,
        isRepairEligible,
        isCustomerView,
        uprn,
        isInDefects,
        repairType: sorCode,
        heatingRepairContext
      });


const request = (method: string) => (basePath: string) => async (
  path?: string,
  data?: object,
  metadata?: RequestMetadata
) => {
  // We check to see if we are running Cypress, if so,
  // we use the generated idtoken set in the localStorage

  // const accessToken =
  //   // @ts-ignore
  //   window.Cypress ? localStorage.getItem('msal.idtoken') : await getAccessToken()
  //   console.log('accessToken', accessToken)
  const _sessionId = localStorage.getItem('sessionId')||"";
  const _corelationId = localStorage.getItem('corelationId')||"";
  const _agent = localStorage.getItem('agent')||"";
  const _channel=process.env.REACT_APP_X_CHANNEL_IDENTIFIER;
  
  trackEvent(
    window.location.pathname,process.env.REACT_APP_X_CHANNEL_IDENTIFIER,_corelationId,
    _sessionId
  );

  const opt: any = {
    method,
    headers: {
          Authorization: "",
          'ocp-apim-subscription-key': apimKey,
          Origin: origin,
          'cache-control': 'no-cache',
          'if-match': metadata?.eTag || 'eTag', // N.B. empty string currently avoids validation of eTag - should probably prevent call in the first place if it's not present
          'x-channel-identifier': _channel,
          'x-agent-identifier': _agent,
          'x-correlation-identifier': _corelationId,
          'x-forwarded-for': '',
          "x-session-identifier": _sessionId,
          ...(data && {
              'Content-Type': 'application/json'
          }),
      },
    ...(data && { body: JSON.stringify(data) 
    }),
  }
  
  return fetch(`${basePath}${path}`, opt).then(
      async res => {
          const response = await res.json().catch(() => null)
      if (res.status === 401) {
          window.sessionStorage.clear();
          window.localStorage.clear();
          window.location.assign(window.location.origin)
          throw new ApiError({ status: res.status, title: response.title }, response.errors)
      }
      if (res.status === 204)
          return res.status;

      if (!res.ok) {
         throw new ApiError({ status: res?.status, title: response ? response?.title : res?.statusText })
      }
      if (!data) {
        // If it's not post, we inject the eTag that used to come as a part of the body, in the response.
        const eTagInHeader = res?.headers?.get('eTag')
        if (eTagInHeader) {
          return { ...response, eTag: eTagInHeader }
        }
      }
      
      const locationHeader = res?.headers?.get('Location')
      if (locationHeader) {
        return { ...response, location: locationHeader }
      }
     
      return response
      
    },
    err => {
      throw new ApiError({ status: err.status })
    }
  )
}

export const getReferenceDataRepair = (
  
  namespacenames: string
): ApiResponse<ReferenceData[]> =>
  get(referenceApiUrl)(
  
    `repairs/reference-data?namespacenames=${namespacenames}`
  );
  
  export const createWorkOrderRepair = (data: Partial<CurrentRepair>) => {
    const worksOrder = new WorksOrderRequest(data);
    return post(worksordersApiUrl)("/works-orders", worksOrder);
  };
  
  export const updateWorkOrderRepair = (
    repair: Partial<CurrentRepair>,
    id: string,
    eTag: string
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return patch(worksordersApiUrl)(
      `/works-orders/${id}`,
      new WorksOrderRequest(repair),
      { eTag }
    );
};

export const getContractors = async (contractorSite: string): ApiResponse<ContractorV2[]> => {
    const contractorResponse: ContractorV2[] = await get(contractorV2ApiUrl)(`/contractors?site-identifier=${contractorSite}`);
    return contractorResponse;
};

  export const getDeterminedContractor = (
    identifier: string,
    priority: string,
    region: string,
    borough: string,
    repairType: string,
    repairTime: string,
    uprn: string,
    raisedDate: string,
    repairCauseId: number,
    maintenanceResponsibility: string,
    repairResponsibility: string
  ): ApiResponse<ContractorV2[]> => {
    return get(contractorV2ApiUrl)(
      `/worksorder/${identifier}/contractors?priority=${priority}&region=${region}&borough=${borough}&repairType=${repairType}&repairTime=${repairTime}&uprn=${uprn}&raisedDate=${raisedDate}&repairCauseId=${repairCauseId}&maintenanceResponsiblity=${maintenanceResponsibility}&repairResponsibility=${repairResponsibility}`
    );
  };
  
  export const getContractorPriority = ({
    contractorIdentifier,
    priority,
    isRecall,
    sorCode,
    isEMA,
  }: {
    contractorIdentifier: string;
    priority: string;
    isRecall: boolean;
    sorCode: string;
    isEMA: boolean;
  }): ApiResponse<ContractorPriority> => {
    const dateTime = moment().format("YYYY-MM-DD HH:mm");
    return get(contractorV2ApiUrl)(
      `/contractors/${contractorIdentifier}/workinghours?priority=${priority}&dateTime=${dateTime}&isRecall=${isRecall}&repairReason=${sorCode}&isEMA=${isEMA}`
    );
  };
  export const getAccount = (id: string): ApiResponse<any> =>
  get(accountsApiUrl)(`/accounts/${id}?serviceChargeCategory=universalcreditcard`);
  export const getAlert = (id: string): ApiResponse<any> =>
  get(assetapiApiUrl)(`/assets/${id}/alerts`);
  export const getKeyContact = (id: string): ApiResponse<any> =>
  get(assetApiUrl)(`/${id}/associates`);
  export const getTransactionsSearch = (
    id: string,
    Limit: number,
    Skip: number,
    DateFrom?: string,
    DateTo?: string,
    searchText?: string,
    transactiontype?: string
  ): ApiResponse<any> =>
    
    get(transactionApiUrl)(
      `/accounts/${id}/transactions?pageNumber=1&pageSize=3&Limit=${Limit}&Skip=${Skip}&DateFrom=${DateFrom}&DateTo=${DateTo}&searchText=${searchText}&${transactiontype}`
    );
export const getCustomerData = (queryParam : any): ApiResponse<CustomerResponse> => {

  return get(customerApiUrl)(
    queryParam.limit !== undefined && queryParam.limit !== null ? 
      `search/customers?searchText=${queryParam.searchText}&limit=${queryParam.limit}&skip=${queryParam.skip}&customerType=${queryParam.customerType}&orderBy=${queryParam.orderBy}&customerTenancyTypeGroups=${queryParam.tenancyType}&uprn=${queryParam.uprn}` :
      `search/customers?searchText=${queryParam.searchText}&skip=${queryParam.skip}&customerType=${queryParam.customerType}&orderBy=${queryParam.orderBy}&customerTenancyTypeGroups=${queryParam.tenancyType}&uprn=${queryParam.uprn}`
    );
}
    
export const getPropertyData = (queryParam : any): ApiResponse<PropertyResponse[]> =>
    get(assetApiUrl)(`?searchText=${queryParam.searchText}&limit=${queryParam.limit}&skip=${queryParam.skip}&orderBy=${queryParam.orderBy}&assetType=${queryParam.assetType}&assetStatus=VOID,OCCP,CLSD`);
 export const GetAppConfigSearhTextCount = async (
      key: string
    ): ApiResponse<any> => {  
      const appConfigResponse = await get(appconfigApiUrl)(
        `configuration/${key}`
      );
      return appConfigResponse;
    };

type WorksOrderSearchParameters = {
  searchText?: string;
  orderBy?: string;
  limit?: number;
  skip?: number;
  raisedSince?: string;
  sor?: string;
  repairType?:string;
  location?: string;
  propertiesIds: string[];
  priority?: string;
  status?: string;
  description?: string;
  raisedUpto?: string;
  assetType?: string;
  isDuplicateCheck?: boolean;
  isTriage?: boolean;
};

export const getWorksOrdersApi = ({
  propertiesIds,
  limit = 10,
  skip,
  searchText = "",
  orderBy = "",
  raisedSince = "",
  sor = "",
  location = "",
  priority = "",
  status = "",
  repairType="",
  description = "",
  raisedUpto = "",
  assetType = "",
  isDuplicateCheck= false,
  isTriage=false,
}: WorksOrderSearchParameters): ApiResponse<WorksOrdersResponse> => {
  const limitQueryParam = !!limit ? `&limit=${limit}` : "";
  const skipQueryParam = !!skip ? `&skip=${skip}` : "";
  if(propertiesIds.length>0){
    const propertiesQueryParams = propertiesIds.join("&propertiesIds=");
  return get(worksordersApiUrl)(
    `/search/works-orders?searchText=${searchText}&orderBy=${orderBy}&raisedSince=${raisedSince}&status=${status}&repairType=${repairType}&sor=${sor}&location=${location}&priority=${priority}&propertiesIds=${propertiesQueryParams}${limitQueryParam}${skipQueryParam}&description=${description}&raisedSince=${raisedSince}&raisedUpto=${raisedUpto}&priority=${priority}&assetType=${assetType}&isDuplicateCheck=${isDuplicateCheck} &isTriage=${isTriage} `
  );
  }
  else{
    return get(worksordersApiUrl)(
      `/search/works-orders?searchText=${searchText}${limitQueryParam}${skipQueryParam}&orderBy=${orderBy}&status=${status}&repairType=${repairType}&priority=${priority}&raisedSince=${raisedSince}&raisedUpto=${raisedUpto}&assetType=${assetType}&isDuplicateCheck=${isDuplicateCheck}&isTriage=${isTriage}`
      );
  }
};
export const getCaseData = (queryParam : any): ApiResponse<CaseSearchData> =>
    get(caseApiUrl)(`?searchText=${queryParam.searchText}&limit=${queryParam.limit}&skip=${queryParam.skip}&orderBy=${queryParam.orderBy}&status=${queryParam.status}&associatedType=${queryParam.assetType}&dateFrom=${queryParam.raisedSince}&dateTo=${queryParam.raisedUpto}&ownerEmailId=${queryParam.ownerEmailId}`);

export const GetAppConfigToggleValue = async (
  key: string
): ApiResponse<any> => {  
  const appConfigResponse = await get(appconfigApiUrl)(
    `configuration/${key}`
  );
  return appConfigResponse;
};

export const putEditProfile = (
  customerId: string,
  profileData: object,
  eTag: string
): ApiResponse<CustomerProfile[]> =>
  put(customerApiUrl)(`customers/${customerId}`, profileData, { eTag });
   
  
export const GetPropertyAssociates = (
  assetId: string
): ApiResponse<any> =>
    get(assetapiApiUrl)(`/assets/${assetId}/associates`);



export const getCancelRepairEligibility = (
    channel: string,
    status: string,
    isDraft: boolean,
    isEmergency: boolean,
    isOutOfHours: boolean,
    isRecall: boolean,
    isEMA: boolean,
    isPotentialDuplicate: boolean,
    contractorSiteIdentifier: string,
    location: string,
    trade: string,
    sorCode: string | null,
    raisedDate: string,
    assetTypeName: string,
    lastAppointmentDate: string,
    userIdentifier: string,
    raisedByIdentifier: string
): ApiResponse<CancelEligibilityOutcome> =>
    post(cancelRepairApiUrl)(`/wo-cancellation-eligibility`, {
        channel,
        status,
        isDraft,
        isEmergency,
        isOutOfHours,
        isRecall,
        isEMA,
        isPotentialDuplicate,
        contractorSiteIdentifier,
        location,
        trade,
        sorCode,
        raisedDate,
        assetTypeName,
        lastAppointmentDate,
        userIdentifier,
        raisedByIdentifier,
    });

export const repaireWorkOrderUpdate = (repair: object, id: string) => {
    return patch(worksordersApiUrl)(`/works-orders/${id}`, repair);
};

export const postUCCCreateArrearsAction = (createArrearsActionData: CreateArrearsDataType): ApiResponse<PremisesEligibility[]> =>
post(clearSessionUrl)(`/customers/${createArrearsActionData.customerIdentifier}/accounts/${createArrearsActionData.accountId}/arrearsaction`, {
  payRef: createArrearsActionData.payRef,
  noteTypeId: createArrearsActionData.noteTypeId,
  text: createArrearsActionData.text,
  assetIdentifier: createArrearsActionData.assetIdentifier
});
export const getUntidyTenancy = (requestData: UntidyTenancyTypes) => 
get(accountsApiUrl)(`/tenancy/${requestData.tenancyId}/notes?uprn=${requestData.uprn}&noteType=${requestData.noteType}`);
export const getUCCReferenceData = () => 
get(accountsApiUrl)(`/revenueaccounts/reference-data?namespacenames=tenancy-ucc-mapping`);
export const getSearchCustomer = (uprn: string) => 
get(assetapiApiUrl)(`/search/customers?searchText=&limit=10&skip=0&customerType=&orderBy=&customerTenancyTypeGroups=&uprn=${uprn}`);

export const getQuestionnaireMasterData = (): ApiResponse<any[]> =>
  get(referenceApiUrl)(
    `/case-types`
  );

  export const getCaseAssetAssociate = (
    assetId: string
  ): ApiResponse<any> =>
    get(assetapiApiUrl)(`/assets/${assetId}/associates`)


    type CaseSearchParameters = {
      searchText?: string;
      orderBy?: string;
      limit?: number;
      skip?: number;
      priority?: string;
      status?: string;
      repairType?:string;
      defaultassocitaedAsset?: string;
      CustomerIdentifiers?: string;
      tenancyIdentifiers?: string
    };
    export const getCasesList = ({
      searchText = "",
      orderBy = "",
      limit = 10,
      skip,
      status = "",
      defaultassocitaedAsset = "",
      CustomerIdentifiers = "",
      tenancyIdentifiers=""
    }: CaseSearchParameters): ApiResponse<CaseSearchData> => {
      return get(referenceApiUrl)(
        `/cases?SearchText=${searchText}&OrderBy=${orderBy}&Status=${status}&Limit=${limit}&Skip=${skip}&${defaultassocitaedAsset}&CustomerIdentifiers=${CustomerIdentifiers}&tenancyIdentifiers=${tenancyIdentifiers}`
      );
    };
    export const getCaseHistories = async (
      caseIdentifier: string,
      activityType= "" 
  ): ApiResponse<CaseHistory> => {
    var caseHistoriesResponse;
    if(isUndefinedNullOrEmpty(activityType)){
      caseHistoriesResponse  = await get(caseApiUrl)(
          `/${caseIdentifier}/historyItems`
      );
    }
    else{
     caseHistoriesResponse = await get(caseApiUrl)(
      `/${caseIdentifier}/historyItems?activity-types=${activityType}`
  );
     }
      return caseHistoriesResponse;
  };

    type CaseSearchParams = {
        searchText?: string;
        orderBy?: string;
        limit?: number;
        skip?: number;
        priority?: string;
        status?: string;
        defaultassociatedAsset?: string;
        customerIdentifiers?: string;
        tenancyIdentifiers?: string
        associatedType?: string;
        raisedSince?: string;
        raisedUpto?: string;
    };

    export const getCaseListData = ({
        searchText = "",
        orderBy = "",
        limit = 10,
        skip,
        status = "",
        defaultassociatedAsset = "",
        customerIdentifiers = "",
        tenancyIdentifiers = "",
        associatedType = "",
        raisedSince = "",
        raisedUpto = "",
        priority=""
    }: CaseSearchParams): ApiResponse<CaseSearchData> => {
        return get(caseApiUrl)(
            `?SearchText=${searchText}&OrderBy=${orderBy}&Status=${status}&Limit=${limit}&Skip=${skip}&${defaultassociatedAsset}&customerIdentifiers=${customerIdentifiers}&tenancyIdentifiers=${tenancyIdentifiers}&associatedType=${associatedType}&dateFrom=${raisedSince}&dateTo=${raisedUpto}&priority=${priority}`
        );
    };
  
    export const repairPriorityDetermination = (description : string)  => {
       return post(eligilibilityDeterminationApiURL)(`/api/v1/repair/priority-determination`, {description})
   }    

export const sendSMSNotification = (assetId:any, data: any): ApiResponse<any> =>
  post(referenceApiUrl)(`assets/${assetId}/communications`, data);
  export const deleteMediaFiles = (
    documentId: string
  ): ApiResponse<any> =>
      deleteRequest(mediaApiUrl)(
        `v1/documents/${documentId}?documentType=cases`);


  export const postMediaFile = (
      mediaData: object
    ): ApiResponse<any> =>
    post(mediaApiUrl)(`v1/documents`, mediaData);
  


  export const getMediaData = (caseIdentifier: string): ApiResponse<any> =>
      get(mediaApiUrl)(`v1/documents?documentType=cases&documentTypeIdentifier=${caseIdentifier}`);
        
  export const getViewMediaDetail = (caseIdentifier: string,documentId: string): ApiResponse<any> =>
       get(mediaApiUrl)(`v1/documents/${documentId}?documentType=cases&documentTypeIdentifier=${caseIdentifier}`);
       export const getRepairMediaData = (id: string): ApiResponse<any> =>
       get(mediaApiUrl)(`v1/works-orders/${id}/documents`);
export const postRepairMediaFile = (
         workOrderId: string,
         mediaData: object
       ): ApiResponse<any> =>
         post(mediaApiUrl)(`v1/works-orders/${workOrderId}/documents`, mediaData);

         export const deleteRepairMediaFiles = (
           workOrderId: string,
           mediaId: string
         ): ApiResponse<any> =>
           deleteRequest(mediaApiUrl)(
             `v1/works-orders/${workOrderId}/documents/${mediaId}`
           );
           export const getRepairViewMediaDetail = (
             id: string,
             mediaId: string
           ): ApiResponse<any> =>
             get(mediaApiUrl)(`v1/works-orders/${id}/documents/${mediaId}`);


             export const updateWorkOrderRepairMedia = (
              repair: Partial<CurrentRepair>,
              id: string,
              eTag: string
            ) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              return patch(mediaApiUrl)(
                `v2/works-orders/${id}`,
                new WorksOrderRequest(repair),
                { eTag }
              );
            };
  
       export const getFinancialReferralReasonData = (  ): ApiResponse<ReferenceData[]> =>
    get(referenceApiUrl)(
      `collection/reference-data?NamespaceNames=financial-referral-reason`
    );

  export const submitFinancialInclusion = (customerId:any, payref:any, data:any):ApiResponse<any> => 
      post(referenceApiUrl)(`customer/${customerId}/account/${payref}/servicerequest`, data)
  
  export const addAnUpdate = (caseIdentifier: string, Data: object): ApiResponse<any> => post(caseApiUrl)(`/${caseIdentifier}/activities`, Data);
     
  export const UpdateAnAction = (caseIdentifier: string, activityIdentifier: string, Data: object) =>
  {return patch(caseApiUrl)(`/${caseIdentifier}/activities/${activityIdentifier}`, Data)};

  export const GetHighRiseProperty = (assetId: string): ApiResponse<any> =>
    get(worksordersApiUrl)(`/assets/${assetId}/associates?expand=interestedParties`);
  
    export const postEnhancedRepairEligibility = (
      enhancedRepairPayload: EnhancedRepairPayload
    ): ApiResponse<EnhancedRepairResponse> =>
    post(enhancedRepairEligibilityApiURL)(`/api/v2/works-orders/enhanced-repair-eligibility`, enhancedRepairPayload);

    export const postNoteForWorkorder = (
      workOrderSerialNumber: string,
      workOrderSequenceNumber: string,
      notesPayload: object
    ): ApiResponse<any> => 
       post(getNotesBaseApiUrl)(
        `/workorders/${workOrderSerialNumber}/${workOrderSequenceNumber}/notes`,notesPayload
    );
  
    export const GetExternalWorkOrderContractors = async (
      key: string
    ): ApiResponse<any> => {  
      const response = await get(appconfigApiUrl)(
        `configuration/${key}`
      );
      return response;
    };
  
export const get = request('GET')
export const put = request('PUT')
export const patch = request('PATCH')
export const post = request('POST')
export const deleteRequest = request('DELETE')
