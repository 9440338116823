
import { trackEvent } from '../../appInsights/TelemetryService'
import { ApiError } from '../ApiError'
import { isNullOrUndefined } from "../../utils/formatting";

export class CreateAppointmentParams {
  
  public reasonCode: string = ''
  public remarks: string = '' 
  public diaryCode: string = ''
  public jobNumber: string = ''  
  public appointmentFromDate: Date = new Date()
  public appointmentToDate: Date = new Date()
  public externalCreateDateTime: Date = new Date()
  public isPreferences: boolean = false
  public externalReferenceId: string = ''
  public availabilityType: string = ''  
  public isInsideSla: boolean = false
}

const apimBaseUrl = process.env.REACT_APP_APIM_BASE_URL  || '';
const apimKey = process.env.REACT_APP_OCP_APIM_KEY
const origin = process.env.REACT_APP_WEB_APP_HOSTNAME;

const request = (method: string) => (basePath: string) => async (
  path?: string,
  data?: object,
  metadata?: any
) => {
  const _sessionId = localStorage.getItem('sessionId')||"";
  const _corelationId = localStorage.getItem('corelationId')||"";
  const _channel=process.env.REACT_APP_X_CHANNEL_IDENTIFIER;
  const _agent = localStorage.getItem('agent')||"";
  
  trackEvent(
    window.location.pathname,process.env.REACT_APP_X_CHANNEL_IDENTIFIER,_corelationId,
    _sessionId
  );

  const opt: any = {
    method,
    headers: {
          Authorization: "",
          'ocp-apim-subscription-key': apimKey,
          Origin: origin,
          'cache-control': 'no-cache',
          'if-match': metadata?.eTag || 'eTag', // N.B. empty string currently avoids validation of eTag - should probably prevent call in the first place if it's not present
          'x-channel-identifier': _channel,
          'x-agent-identifier': _agent,
          'x-correlation-identifier': _corelationId,
          'x-forwarded-for': '',
          "x-session-identifier": _sessionId,
          OperationGroupKey: metadata.operationKey,
          ...(data && {
              'Content-Type': 'application/json'
          }),
      },
    ...(data && { body: JSON.stringify(data) }),
  }
  
  return fetch(`${basePath}${path}`, opt).then(
      async res => {
          const response = await res.json().catch(() => null)
      if (res.status === 401) {
          window.sessionStorage.clear();
          window.localStorage.clear();
          window.location.assign(window.location.origin)
          throw new ApiError({ status: res.status, title: response.title }, response.errors)
      }
      if (opt.method === "POST" && res.status === 204)
          // Successful Create Appointment
          return res.status;

      if (!res.ok) {
        // Failure in both POST & GET

        const errorTitle = !isNullOrUndefined(response?.title) ? response?.title : 
        !isNullOrUndefined(res?.statusText) ? res?.statusText :
        !isNullOrUndefined(res?.type) ? res?.type : "Gen Error";

          if(opt.method === "GET") {
            // No Contractor Availability scenario (404) & Availability Error Scenario
            // Handled in component hence passing details
            return {statusCode: res.status, title: errorTitle};
          }

          // Throw Error when Create Appointment fails (ie POST is not 204)
          throw new ApiError({ status: res?.status, title: errorTitle })
          
          
      }
      if (!data) {
        // If it's not post, we inject the eTag that used to come as a part of the body, in the response.
        const eTagInHeader = res?.headers?.get('eTag')
        if (eTagInHeader) {
          return { ...response, eTag: eTagInHeader }
        }
      }
      
      const locationHeader = res?.headers?.get('Location')
      if (locationHeader) {
        return { ...response, location: locationHeader }
      }
     
      // Successful Get Availability response
      return response
      
    },
    err => {
      throw new ApiError({ status: err.status })
    }
  )
}

export const post = request('POST')
export const get = request('GET')

export const postAppointment = (workorderid: string, params: CreateAppointmentParams, operationKey: string, contractorName: string) =>
    post(apimBaseUrl)(
        `/colleagueselfserve/api/v1/works-orders/${workorderid}/appointments?contractorName=${contractorName}`,
        params,
        {
            operationKey,
        }
    )
  
export const getAvailabilityForAppointment = (workorderid : string, referenceNumber: string, operationKey: string,contractorName:string,isExternalWorkorderRequired:boolean) =>
  get(
    apimBaseUrl
  )(
    `/colleagueselfserve/api/v1/works-orders/${workorderid}/appointments/availability?referenceNumber=${referenceNumber}&IsExternalWorkOrderRequired=${isExternalWorkorderRequired}&contractorName=${contractorName}`,
    undefined,
    { operationKey }
  )